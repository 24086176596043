export const environment = {
  production: false,
  env: 'dev',
  website_base_url: "https://dev.pmsgyapp.com/authenticate/api/verifyAccess/website",
  discom_base_url: "https://dev.pmsgyapp.com/authenticate/api/verifyAccess/discom",
  consumer_base_url: "https://dev.pmsgyapp.com/authenticate/api/verifyAccess/consumer",
  vendor_base_url: "https://dev.pmsgyapp.com/authenticate/api/verifyAccess/vendor",
  authenticate_base_url: "https://dev.pmsgyapp.com/authenticate/api/verifyAccess",
  admin_base_url: "https://dev.pmsgyapp.com/authenticate/api/verifyAccess/admin",
  govt_building_base_url: "https://dev.pmsgyapp.com/authenticate/api/verifyAccess/govt-building",
  knowledge_partner_base_url: "https://dev.pmsgyapp.com/authenticate/api/verifyAccess/partner",
 
  token_url: "https://dev.pmsgyapp.com/authenticate",

  consumer_direct_url: "/d/consumer",
  vendor_direct_url: "/d/vendor",
  discom_direct_url: "/d/discom",
  admin_direct_url: "/d/admin",
  govt_building_direct_url: "/d/govt-building",

  AWS_ACCESS_KEY_ID: '',//your-access-key-id
  AWS_SECRET_ACCESS_KEY: '',//your-secret-access-key
  AWS_REGION: '',//'your-region
  admin_dotnet_base_url: "http://uam-ms-dev-service.mnre-dev.svc.cluster.local:8189",
  
  auth_client_id: "auth-ms-client",
  consumer_client_id: "consumer-ms-client",
  vendor_client_id: "vendor-ms-client",
  discom_client_id: "discom-ms-client",
  admin_client_id: "admin-ms-client",
  govtb_client_id: "govtb-ms-client",

  //Domains
  website_ui_host:"dev.pmsgyapp.com",
  vendor_ui_host:"vendor.dev.pmsgyapp.com",
  consumer_ui_host: "consumer.dev.pmsgyapp.com",
  admin_ui_host: "admin.dev.pmsgyapp.com",
  discom_ui_host: "discom.dev.pmsgyapp.com",
  govt_ministry_ui_host: "govbuild.dev.pmsgyapp.com",
  knowledge_partner_ui_host: "partner.dev.pmsgyapp.com"
};